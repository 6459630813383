<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Holidays Page'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Holidays Page Edit'"
      :title="'Holidays Page Edit'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Update</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
        <div v-if="langList.length" class="form-create__langs">
          <div
            v-for="lang in langList"
            :key="lang.id"
            @click="openLangModal(lang)"
            class="form-create__lang"
          >
            {{ lang.seoName }}
          </div>
        </div>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>

          <p class="form-create__label">SEO Name (for URL)</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'seoName'"
              :label="'seo name'"
              :value="formDataToSend.seo_name"
              :type="'text'"
              :placeholder="'SEO Name'"
              :error-messages="validationErrors['seoName']"
              readonly
              isBordered
            />
          </div>
          <p class="form-create__label">Link (url)</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'link'"
              :label="'link'"
              :type="'text'"
              :placeholder="'Link (url)'"
              :error-messages="validationErrors['link']"
              isBordered
            />
          </div>

          <p class="form-create__label">Link Name</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'link_name'"
              :label="'link_name'"
              :type="'text'"
              :placeholder="'Link Name'"
              :error-messages="validationErrors['link_name']"
              isBordered
            />
          </div>

          <p class="form-create__label">Templates (drive id via enter)</p>
          <div class="input-container">
            <InputTextarea
              :set-value="formDataToSend"
              :id="'templates'"
              :label="'templates'"
              :placeholder="'Templates'"
              :error-messages="validationErrors['templates']"
              isBordered
            />
          </div>
          <div v-if="validationErrors" class="form-create__error">
            <div v-for="(item, index) in Object.values(validationErrors)" :key="index">
              <div v-if="Object.keys(validationErrors)[index].includes('templates')">
                {{ item[0] }}
              </div>
            </div>
          </div>
          <div class="input-container">
            <MainCheckbox :label="'Active'" :id="'status'" :set-value="formDataToSend" />
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
        <MainModal v-if="langModal" :on-close="closeLangModal" :modal-center="true">
          <div class="lang-modal">
            <div class="lang-modal__title">Translations for {{ chosenLang.name }}</div>
            <form @submit.prevent="handleSubmitLang">
              <p class="form-create__label">Name</p>
              <div class="input-container">
                <InputText
                  :set-value="formDataToSendLang"
                  :id="'name'"
                  :label="'name'"
                  :type="'text'"
                  :placeholder="'name'"
                  isBordered
                />
                <div
                  @click="handleTranslate('name', chosenLang.name)"
                  class="form-create__translate"
                >
                  Translate (Chat GPT)
                </div>
              </div>
              <p class="form-create__label">Link Name</p>
              <div class="input-container">
                <InputText
                  :set-value="formDataToSendLang"
                  :id="'link_name'"
                  :label="'link_name'"
                  :type="'text'"
                  :placeholder="'Link Name'"
                  isBordered
                />
                <div
                  @click="handleTranslate('link_name', chosenLang.name)"
                  class="form-create__translate"
                >
                  Translate (Chat GPT)
                </div>
              </div>
              <div class="form-create__btn">
                <MainButton :type="'submit'">Update</MainButton>
              </div>
            </form>
          </div>
        </MainModal>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import templatesApi from "~/api/templates";
import langApi from "~/api/lang";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import InputTextarea from "~/components/atoms/inputs/InputTextarea.vue";
import MainModal from "~/components/molecules/MainModal.vue";

export default {
  name: "HolidaysEdit",
  metaInfo: {
    title: "Holidays Page Edit",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        seo_name: "",
        status: false,
        link: "",
        link_name: "",
        templates: "",
      },
      formDataToSendLang: {
        name: "",
        link_name: "",
      },
      langEdit: false,
      langModal: false,
      chosenLang: null,
      static: null,
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    MainCheckbox,
    InputTextarea,
    MainModal,
  },
  mounted() {
    this.getItem();
    this.getLang();
  },
  computed: {
    ...mapState("templates", ["error", "loadingProcess", "validationErrors"]),
    ...mapState("lang", ["lang", "langList"]),
    getFirstLink() {
      return ROUTE.HOLIDAYS_LIST.replace(":page", 1);
    },
    getId() {
      return this.$route.params.id;
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        name: this.formDataToSend.name,
        seo_name: this.formDataToSend.seo_name,
        status: this.formDataToSend.status,
        link: this.formDataToSend.link,
        link_name: this.formDataToSend.link_name,
      };

      const templates = this.formDataToSend.templates.toLowerCase().split(/\r\n|\r|\n/g);
      const templatesSend = templates.filter((str) => str.trim() !== "");
      data.templates = templatesSend;

      this.$store.commit("templates/setLoading", true);
      const url = `/${this.getId}`;
      try {
        templatesApi
          .editHolidays(url, data)
          .then((res) => {
            if (res.success) {
              this.$store.commit("templates/setError", {});
              this.$store.commit("templates/setValidationErrors", {});
              this.handleBackToList();
            }
          })
          .catch((error) => {
            const { data } = error.response;
            this.$store.commit("templates/setError", data);
            if (data.errors) {
              this.$store.commit("templates/setValidationErrors", data.errors);
            }
          })
          .finally(() => {
            this.$store.commit("templates/setLoading", false);
          });
      } catch (error) {
        console.log(error);
      }
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.HOLIDAYS_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
    },
    getItem() {
      const url = `/${this.getId}`;
      try {
        templatesApi.getHolidaysItems(url).then((res) => {
          this.$store.commit("templates/setError", {});
          this.$store.commit("templates/setValidationErrors", {});
          const data = res.data;
          this.static = res.data;
          this.formDataToSend.name = data.name;
          this.formDataToSend.seo_name = data.seoName;
          this.formDataToSend.status = data.status;
          this.formDataToSend.link = data.link;
          this.formDataToSend.link_name = data.linkName;

          if (data.templates) {
            this.formDataToSend.templates = data.templates.join("\n");
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    getLang() {
      let url = `?page=${this.page}`;
      this.$store.dispatch("lang/getLangList", url);
    },
    closeLangModal() {
      this.chosenLang = null;
      this.langModal = false;
      this.formDataToSendLang.name = "";
      this.formDataToSendLang.link_name = "";
      this.langEdit = false;
    },
    openLangModal(lang) {
      this.langModal = true;
      this.chosenLang = lang;

      const translate = this.static.translation;
      console.log(this.chosenLang.id);
      let translateStatic = translate.find((item) => item.langId === this.chosenLang.id);
      if (translateStatic) {
        this.formDataToSendLang.name = translateStatic.name;
        this.formDataToSendLang.link_name = translateStatic.link_name;
        this.formDataToSendLang.id = translateStatic.id;
        this.langEdit = true;
      }
    },
    handleSubmitLang() {
      this.$store.commit("statics/setLoading", true);
      const data = {
        name: this.formDataToSendLang.name,
        link_name: this.formDataToSendLang.link_name,
        holiday_id: this.static.id,
        lang_id: this.chosenLang.id,
      };
      if (this.langEdit) {
        langApi
          .editLangHoliday(data, `/${this.formDataToSendLang.id}`)
          .then(() => {
            this.$store.commit("statics/setLoading", false);
            this.closeLangModal();
            this.getItem();
          })
          .catch((error) => {
            this.$store.commit("statics/setLoading", false);
            console.log(error);
          });
      } else {
        langApi
          .createLangHoliday(data)
          .then(() => {
            this.$store.commit("statics/setLoading", false);
            this.closeLangModal();
            this.getItem();
          })
          .catch((error) => {
            this.$store.commit("statics/setLoading", false);
            console.log(error);
          });
      }
    },
    handleTranslate(el, lang) {
      this.$store.commit("statics/setLoading", true);
      const data = {
        text: this.formDataToSend[el],
        language: lang,
      };
      langApi
        .getTranslation(data)
        .then((res) => {
          this.$store.commit("statics/setLoading", false);
          this.formDataToSendLang[el] = res.data.response;
        })
        .catch((error) => {
          this.$store.commit("statics/setLoading", false);
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select {
  max-width: 300px;
}
.checkbox-container {
  @include scrollBar;

  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;

  &_margin {
    margin-top: 20px;
  }
}
.checkbox-container__item {
  padding: 5px 0;
}
</style>
